import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MenuLinks } from '../MenuLinks/MenuLinks';
import '../../helpers/fontawesome';
import "./HamburgerMenuSidebar.scss";

const HamburgerMenuSidebar = ({toggleSidebar, isOpen}) => {
  

  return (
    <aside className={`hb-sidebar ${isOpen? 'show-sidebar' : '' }`}>
      <button className="close-btn" onClick={toggleSidebar}>
        <FontAwesomeIcon icon='times' />
      </button>
      <div className="side-container">
        <MenuLinks styleClass={`${isOpen? "sidebar-links" : '' }`} />
      </div>
    </aside>
  )
}

export default HamburgerMenuSidebar;
