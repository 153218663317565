import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import { Link } from "gatsby";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useMsal } from "@azure/msal-react";
import { navigate } from "gatsby";

import authMenuLinksData from "../../constants/links-auth";
import publicMenuLinksData from "../../constants/links-public";
import { getNameInitials } from "../../helpers/functions";
import { getIsUserAuthenticated, getUserName, getUserProfile, isBrowser } from "../../services/auth";

import "./MenuLinks.scss";
import { loginRequest } from "../../msal/authConfig";
import { generateRandomString } from "../../helpers/serviceHelper";

export const MenuLinks: React.FC = ({ styleClass }: any) => {
  const { instance, accounts, inProgress } = useMsal();

  const [ isAuthenticated, setIsAuthenticated ] = useState(getIsUserAuthenticated());
  
  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => {
      const isAuth = getIsUserAuthenticated();
      
      setIsAuthenticated(isAuth);

      if (isAuth || (++counter >= 5)) {
        clearInterval(interval);
      }
    }, 1000);
  }, [])

  const signupUrl = process.env.GATSBY_SIGNUP_URL;
  const signupUrlWithCodeChallenge = `${signupUrl}&code_challenge=${generateRandomString(50)}`;

  const mobileView = isBrowser() && window.outerWidth < 768;

  const defaultLinks = isAuthenticated
    ? authMenuLinksData.map(link => {
        if (link.text === "Training" || link.text === "Forums") {
          return (
            <li className="nav-item" key={link.id}>
              <Link target="_blank" className="nav-link" to={link.url}>{link.text}</Link>
            </li>
          )
        }
        else {
          return (
            <li className="nav-item" key={link.id}>
              <Link className="nav-link" to={link.url}>{link.text}</Link>
            </li>
          )
        }
      })
    : publicMenuLinksData.map(link => (
          <li className="nav-item" key={link.id}>
            <Link className="nav-link" to={link.url}>{link.text}</Link>
          </li>
        )
      );

  const getAuthLinks = () => {     
    let lines: any;

    if (isAuthenticated) {

      const gatsbyUserProfile = getUserProfile();
      
      lines = (
        <>
          <li className="nav-item">
            <Link className="nav-link" to="/userprofile">User Profile</Link>
          </li>
          { gatsbyUserProfile.isOrgManager ? 
          <li className="nav-item">
            <Link className="nav-link" to="/manageorg">Manage Organisation</Link>
          </li>
          : <></>} 
          <li className="nav-item" key={99}>
            <div className="nav-link" onClick={(() => handleLogout())}>Sign Out</div>
          </li>
        </>
      );
    }
    else {
      lines = (
        <>
          <li className="nav-item" key={98}>
            <Link className="nav-link" to={signupUrlWithCodeChallenge}>Sign Up</Link>
          </li>
          <li className="nav-item" key={99}>
            <div className="nav-link" onClick={(() => instance.loginRedirect(loginRequest))}>Sign In</div>
          </li>
        </>
    );
    }

    return lines;
  }

  const handleLogout = () => {
    isBrowser() && localStorage.clear();
    instance.logoutRedirect();
    // navigate('/signout');
  };

  const name = getUserName();

  // const name = accountInfo?.idTokenClaims
  //             ? accountInfo?.idTokenClaims?.given_name + ' ' + accountInfo?.idTokenClaims?.family_name
  //             : '';

  const gatsbyUserProfile = getUserProfile();

  let links: any = null;

  if (mobileView) {
    links = getAuthLinks();
  }
  else if (isAuthenticated) {
    links = (
      <li className="nav-item">
      <div className="profileDropdown">
          <DropdownButton title={getNameInitials(name)}> 
            <DropdownItem className="nav-link" href="/userprofile">User Profile</DropdownItem>
            { gatsbyUserProfile.isOrgManager ? <DropdownItem className="nav-link" href="/manageorg">Manage Organisation</DropdownItem> : <></>} 
            <DropdownItem className="nav-link" onClick={() => handleLogout()}>Sign Out</DropdownItem>
          </DropdownButton>
      </div>
    </li>
    );
  }
  else {
    links = (
      <>
        <li className="nav-item" >
          <div className="nav-link login" onClick={(() => instance.loginRedirect(loginRequest))}>Sign In</div>
        </li>
        <li className="nav-item" >
          <Link className="nav-link signup" to={signupUrlWithCodeChallenge}>Sign Up</Link>
        </li>
      </>
    );
  }

  return (
    <ul className={`menu-links navbar-nav ms-auto row mx-2 my-0 ${styleClass ? styleClass : ""}`}>
      {defaultLinks}
      {links}
    </ul>
  )
};