import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleRight, faArrowCircleLeft, faCaretUp, faCaretDown, faCaretRight, faCaretLeft, faSearch, faTimes, faMapMarkedAlt, faBookOpen, faMapMarkerAlt, faFilter, faFilePdf } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowCircleRight);
library.add(faArrowCircleLeft);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faCaretRight);
library.add(faCaretLeft);
library.add(faSearch);
library.add(faTimes);
library.add(faMapMarkedAlt);
library.add(faBookOpen);
library.add(faMapMarkerAlt);
library.add(faFilter);
library.add(faFilePdf);