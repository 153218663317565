export const loaderStyle = {
    overlay: (base: any) => ({
        ...base,
        background: 'rgb(211,211,211, 0.7)',
        color: 'black',
        width: '100%',
          '& svg circle': {
            stroke: 'gray'
          },
        zIndex: 100
    })
};

export const spinnerStyle: any = {
  position: 'absolute',
  width: '100%',
  height: '40px',
  textAlign: 'center',
  opacity: '50%',
};

export const waitingText = 'Please wait...';

export enum global {
  TOASTER_TIMEOUT = 3000
};