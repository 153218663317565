import axios, { AxiosRequestConfig } from 'axios';
import { showComposedMessage } from '../helpers/serviceHelper';

const MOODLE_TOKEN = process.env.MOODLE_TOKEN || '630542c4a2857fd7d8c658dadd744b14';
const baseUrl = process.env.GATSBY_API_URL || 'http://localhost:1337';

  export const getUserBadgeDetails = async (accessToken: string | undefined, moodleUserId: string) : Promise<any> => {

    
    const apiUrl = `${baseUrl}/moodle/${moodleUserId}`;
    let response: any;
  
  
    try {
      response = await axios.get(apiUrl);
      return response;
    }
    catch (ex: any) {
      console.log("got error ", ex);
      showComposedMessage('getUserBadgeDetails', apiUrl, ex);
    }
  };
  
  export const retrieveBadge = async (badgeURL: string) : Promise<any> => {
    
    let badgeUrlTruncated = badgeURL.replace("https://training.florabank.org.au/webservice/pluginfile.php/", "");
    const apiUrl = `${baseUrl}/badgeurl`;
    let payload = {
      "url": badgeUrlTruncated
    }
  
    // base64 image
    let image = "";
    try {
      image = await axios.post(apiUrl, payload);
      return image.data;
    }
    catch (ex: any) {
      showComposedMessage('retrieveBadge', apiUrl, ex);
    }
  };