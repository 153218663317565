import React, { useState, useEffect } from 'react';

import Toast, { EToastPosition } from "./Toast";
import checkIcon from './assets/check.svg';
import errorIcon from './assets/error.svg';
import infoIcon from './assets/info.svg';
import warningIcon from './assets/warning.svg';
import { global } from '../../../constants/common';

interface IProps {
    message: string;
    mode: EMessageModeEnum;
}

const ToastContainer = (props: IProps) => {
    const { message, mode } = props;

    const ToasterBackgroundColor = {
        [EMessageModeEnum.SUCCESS]: '#5cb85c',
        [EMessageModeEnum.ERROR]: '#d9534f',
        [EMessageModeEnum.WARNING]: '#f0ad4e',
        [EMessageModeEnum.INFO]: '#5bc0de'
    };

    const ToasterMessageIcon = {
        [EMessageModeEnum.SUCCESS]: checkIcon,
        [EMessageModeEnum.ERROR]: errorIcon,
        [EMessageModeEnum.WARNING]: warningIcon,
        [EMessageModeEnum.INFO]: infoIcon
    };

    const id = Math.floor((Math.random() * 101) + 1);
    
    const title = mode.charAt(0).toUpperCase() + props.mode.slice(1);

    const color = ToasterBackgroundColor[mode];

    const icon = ToasterMessageIcon[mode];

    const toastProperties = {
        id,
        title,
        description: message,
        backgroundColor: color,
        icon: icon
    };

    const flag = !message || !mode;

    return (
        <>
        {
            flag ? <></> :
            <Toast
                toastList={[toastProperties]}
                position={EToastPosition.TOP_RIGHT}
                autoDelete={true}
                dismissTime={global.TOASTER_TIMEOUT}
            />
        }
        </>
    );
}

export enum EMessageModeEnum {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'working',
    INFO = 'info'
};

export default ToastContainer;