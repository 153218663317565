
const menuLinksData = [
  {
    id: 1,
    text: "Home",
    url: "/"
  },
  {
    id: 2,
    text: "Guidelines",
    url: "/guidelines"
  },
  {
    id: 3,
    text: "News",
    url: "/news"
  },
  {
    id: 4,
    text: "Forums",
    url: "http://discourse.florabank.org.au"
  },
  {
    id: 5,
    text: "Seed People",
    url: "/map"
  },
  {
    id: 6,
    text: "Training",
    url: "/training"
  },
  {
    id: 7,
    text: "About",
    url: "/about"
  },
  {
    id: 8,
    text: "Resources",
    url: "/resources"
  },
];

export default menuLinksData;
