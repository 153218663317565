import { EMessageModeEnum } from "../components/shared/Toast/ToastContainer";
import { getUserBadgeDetails, retrieveBadge } from "../services/moodleService";

export const getNameInitials = (name: string) => {
    if (name?.length === 0) return '';

    const parts = (name?.replace('  ', ' ').trimRight() + ' ').split(' ');
    return parts.length > 0 ? parts[0][0] + (parts.length > 1 ? parts[1][0] : '') : '';
};

export const getSpaceDelimitedString = (text: string, removeText1: string = 'Data', removeText2: string = '', removeText3: string = '') => {
    let result = '';
    
    removeText2 = removeText2.replace(removeText1, '');

    let text2 = text.replace(removeText1, '');
    text2 = text2.replace(removeText2, '');
    text2 = text2.replace(removeText3, '');

    const firstLetter = text2.charCodeAt(0);
    result = String.fromCharCode(firstLetter - ((firstLetter >= 97) && (firstLetter <= 122) ? 32 : 0));

    let asc1, asc2 = 0;

    for (let i = 1; i < text2.length; i++) {
        asc1 = text2.charCodeAt(i);
        asc2 = text2.charCodeAt(i - 1);
        result += ((asc1 >= 65) && (asc1 <= 90) && ((asc2 < 65) || (asc2 > 90)) ? ' ' : '') + text2[i];
    }

    return result;
}

export const getBadges = async (moodleUserId: string, showMessage: Function) => {
    console.log("jm: error while trying to do badge stuff ", moodleUserId);
    let moodleBadges = [];
    try {
        let badgeDetailsResponse = await getUserBadgeDetails('', moodleUserId);
        let badges = badgeDetailsResponse?.data.badges;
        console.log("jm: badges badgeDetailsResponse ", badges);
        
        for (let badgeElement = 0; badgeElement < badges.length; badgeElement++) {
            
            const getBadgeResponse = await retrieveBadge(badges[badgeElement].badgeurl);
            if (getBadgeResponse?.length>0) {
                moodleBadges.push(
                    {
                        b64data: getBadgeResponse, 
                        name: badges[badgeElement].name
                    }
                )
            } else {
                showMessage('Error while trying to retrive the badges', EMessageModeEnum.ERROR);
            }
        }
    } catch(e: any) {
        showMessage('Error while trying to retrive the badges ' + e.toString(), EMessageModeEnum.ERROR);
    }

    console.log("jm: badge response ", moodleBadges);
    return moodleBadges;
};
