import { Link } from "gatsby";
import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

//import logo from "../../assets/images/ausgov_logo.png";
import "./Footer.scss";

const Footer = () => {

  const data = useStaticQuery(query);
  const {
    allStrapiHomePage: {nodes: heroContents}
  } = data;

  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="container-fluid text-center">
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/termsconditions">Terms &amp; Conditions</Link></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-image">
          <Image fluid={heroContents[0].footerImage.childImageSharp.fluid} alt="logo" />
        </div>
        <p>
          {heroContents[0].footerText}
        </p>
      </div>
      <div className="footer-copyright text-center py-3">© 2021 Copyright</div>
    </footer>
  )
}


const query = graphql`
{
  allStrapiHomePage {
    nodes {
      HeroContentP {
        contents
      }
      heroctatext
      heroctalink
      GuidelinesTextP {
        contents
      }
      SecondHeroContentP {
        contents
      }
      secondheroctatext
      secondheroctalink
      reviewsTitle
      footerText
      footerImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  },
}`

export default Footer
