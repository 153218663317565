import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby"
import React from "react"

import logo from "../assets/images/logo.png";
import { MenuLinks } from './MenuLinks/MenuLinks';
import "./Navbar.scss";

const Navbar = ({toggleSidebar}) => {
  return (
    <nav className="navbar navbar-expand-md navbar-light header-green">
    
    <Link to="/" className="navbar-brand">
      <img src={logo} alt="logo" />
    </Link>
    <button type="button" className="toggle-btn ms-auto" onClick={toggleSidebar}>
      <FontAwesomeIcon icon={faAlignRight} />
    </button>
    <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
      <MenuLinks styleClass="" />
    </div>
</nav>
  
  )
}

export default Navbar


{/* // <nav className="navbar">
  //   <div className="nav-center">
  //     <div className="nav-header">
  //       <img src={logo} alt="logo" />
  //     </div>
  //   </div>
    // <PageLinks styleClass="nav-links">
    // </PageLinks>
  // </nav> */}