import React from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar";
import HamburgerMenuSidebar from "./HanburgerMenuSidebar/HamburgerMenuSidebar";
import Footer from "./Footer/Footer";

const Layout = ({ children}) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const height = '200px';//window.outerHeight - 456;
  
  const style = {
    minHeight: height
  };
  
  return children ? 
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <HamburgerMenuSidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
      <div style={style}>
        {children}
      </div>
      <Footer />
    </>
    : 
    <></>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;